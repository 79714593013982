/* Card container */
$primary-color: rgb(250,250,250);
$hover-color: rgb(15, 48, 86);
$active-color:#0a0502;
$background:#f3f5f5;
$selectorback:rgb(235, 249, 252);
$primary-text:rgb(10, 59, 206);
$selector-text :rgb(52, 51, 51);
$btn-bg:rgb(13,18,130);
$input-bg:rgb(236,236,243);
$checkbox-bg:rgb(217,217,217);
.card {
    margin: 0 0; /* Center the card horizontally */
    border-radius: 8px; /* Rounded corners */
    overflow: hidden; /* Hide any content that might overflow */
    transition: transform 0.4s ease;
    box-shadow: 8px 20px 10px rgba(57, 131, 242, 0.1); /* Set box shadow */

/* Frame 8 */

width: 402px;
height: 318px;
background: #FFFFFF;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 30px;

// background: linear-gradient(135deg, #3C8CE7 0%, #00EAFF 100%);
  }
  .card:hover{
    transform: scale(1.1);
  }

  .cardAction{
    position: absolute;
  }
  
  /* Card image */
  .card img {
    width: 70px; /* Ensure the image fills the container */
    height: 70px; /* Maintain aspect ratio */

  }
  
  /* Card content */
  .card-content {
    padding: 20px; /* Padding around the content */
  }
  
  /* Title */
  .cardName {
    font-size: 22px; /* Title font size */
    font-weight: bold; /* Title font weight */
    margin-top: 20px;
    color:black
  }
  
  /* Description */
  .card-description {
    font-size: 16px; /* Description font size */
    color: #555; /* Description text color */
  }
  

  .container{
    justify-content: center;
    gap: 40px;
    display: flex;
    align-items: center;
    margin-top: 100px;
    align-items: center;
  }


  .gen-btn{
    //  position: relative;
    // bottom:100px;
    width:50%;
    margin-top: 40px;
    padding: 7px;
    background-color: $btn-bg;
    border-radius: 10px;
    color:white;
    cursor: pointer;
    &:hover{
      background-color: $hover-color;
      color: $primary-color;
    }
    &:active{
      background-color: $active-color;
      color: $primary-color;
    }
  }

  .img{

    transition: transform 0.3s ease;
  }
  .img:hover{
    transform: scale(1.1);
  }