.headerBar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-left: 30px;
    margin-right: 20px;
}

