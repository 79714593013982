/* Card container */
$primary-color: rgb(250, 250, 250);
$hover-color: rgb(15, 48, 86);
$active-color: #0a0502;
$background: #f3f5f5;
$selectorback: rgb(235, 249, 252);
$primary-text: rgb(10, 59, 206);
$selector-text :rgb(52, 51, 51);
$btn-bg: rgb(13, 18, 130);
$input-bg: rgb(236, 236, 243);
$checkbox-bg: rgb(217, 217, 217);


.title {
  color: black;
  font-size: 30px;
  margin-left: 60px;
}


.inputBox11 {
  position: relative;
  background-color: white;
  width: 50%;
  max-width: 50%;
  border-style: solid;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  box-shadow: 8px 20px 10px rgba(57, 131, 242, 0.1);
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  height: 100% !important;
}
.formatContainer {
  height: 50dvh;
  max-height: 50dvh;
  display: flex;
  overflow: auto;
  gap: 20px;
  padding:0px 30px 30px 30px;
}

.masterFormatContainer {
  height: 70dvh;
  max-height: 80dvh;
  display: flex;
  overflow: auto;
  gap: 20px;
  padding:0px 30px 30px 30px;
}


.copyBtn {
  display: flex;
  justify-content: end;
}

.gen-btn {
  margin-top: 40px;
  padding: 10px;
  background-color: $btn-bg;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: $hover-color;
    color: $primary-color;
  }

  &:active {
    background-color: $active-color;
    color: $primary-color;
  }
}

.clear-btn {
  margin-top: 40px;
  padding: 7px;
  background-color: rgb(200, 202, 202);
  border-radius: 10px;
  color: $btn-bg;
  cursor: pointer;

  &:hover {
    background-color: $hover-color;
    color: $primary-color;
  }

  &:active {
    background-color: $active-color;
    color: $primary-color;
  }
}


.buttonSection {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 30px;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  justify-items: end;
  justify-content: end;
  margin-left: 20px;
  margin-right: 20px;
}

.tab {
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 80vh;
  max-width: 60pc;
}

.textarea {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  width: auto;
  /* Default width */
  max-height: 500px;
  /* Default max-height */
  margin: 20px;
  width: 100pc;
  overflow: auto;
  border: 1px solid rgb(172, 175, 177);
  padding: 15px;
  border-radius: 10px;
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .textarea {
    width: 40%;
    /* Adjust width for smaller screens */
    min-height: auto;
    /* Reset min-height */
    max-height: none;
    /* Reset max-height */
  }
}
.line-numbers {
  // float: left ;
  padding: 10px;
  padding-top: 1px;
  border-right: 1px solid rgb(3, 2, 11);
  height: fit-content;
  background-color: #e3eff3;
  border-radius: 3px;
}

.content {
  padding-left: 10px;
}


/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
    /* Change to single column layout on smaller screens */
  }
}



/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .result {
    flex-direction: column;
    /* Stack items vertically on smaller screens */
  }

  .content {
    min-width: auto;
    /* Reset minimum width for smaller screens */
  }
}

.errorSection {
  color: red;
  padding: 5px;
  border: 2px solid red;
  background-color: rgb(248, 232, 232);
  font: 700;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 30pc;
  margin: auto;
  margin-bottom: 20px;

}

.validSection {
  color: green;
  padding: 5px;
  border: 2px solid green;
  background-color: rgb(230, 251, 228);
  font: 700;
  margin-bottom: 10px;
  border-radius: 10px;
  max-width: 30pc;
  margin: auto;
  margin-bottom: 20px;

}

.download-btn{
  height: 26px;
  width: 26px;
  background-color: $btn-bg;
  padding: 5px;
  border-radius: 5px;
}
.resultScreen{
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  width: 50%;
  max-width: 50%;
}
.resultButtonSection{
  display: flex;
  justify-content: end;
}
.masterContainer{
  width: 100%;
  height: 80vh;
}