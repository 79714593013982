

$primary-color: rgb(250,250,250);
$hover-color: rgb(15, 48, 86);
$active-color:#0a0502;
$background:#f3f5f5;
$selectorback:rgb(235, 249, 252);
$primary-text:rgb(10, 59, 206);
$selector-text :rgb(52, 51, 51);
$btn-bg:rgb(13,18,130);
$input-bg:rgb(236,236,243);
$checkbox-bg:rgb(217,217,217);
@mixin flex-withOutRowOrColumn{
  display: flex;
  justify-content: center;
  align-items: center;
}
.center{
    @include flex-withOutRowOrColumn();
    margin-top: 80px;
    flex-direction: row;
    height: 60vh;
}
.centerView{
  @include flex-withOutRowOrColumn();
  flex-direction: column;
  height: 60vh;
}

.secret{
    margin: 30px;
}

p{
font-weight: 700;
font-size: larger;
}
/* Style for the error message box */
.error-box {
    display: inline-block;
    padding: 10px;
    width: 300px;
    background-color: #2a44ef;
    color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
  }
  
  /* Style for the close button */
  .error-box .close-button {
    cursor: pointer;
    font-weight: bold;
    float: right;
    margin-left: 10px;
  }
 /* YourComponent.css */

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content; /* Adjust this to your liking */
  background-color: white; /* Optional background color */
}

.fit-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* You can adjust this to "cover" if you want it to cover the container */
}
