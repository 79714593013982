$primary-color: rgb(250,250,250);
$hover-color: rgb(15, 48, 86);
$active-color:#0a0502;
$background:#f3f5f5;
$selectorback:rgb(235, 249, 252);
$primary-text:rgb(10, 59, 206);
$selector-text :rgb(52, 51, 51);
$btn-bg:rgb(13,18,130);
$input-bg:rgb(236,236,243);
$checkbox-bg:rgb(217,217,217);
$disabled-bg:rgb(236, 239, 241);
$disabled-text:rgb(135, 198, 253);

.header{
    // position: relative;
    background-color: $background;
    // min-width: 450px;
    min-height: 60vh;
    border-radius: 20px;
    grid-template-rows: 1fr auto; /* 1fr for main content, auto for button */
}
.header-1{
    position: relative;
    flex-wrap: wrap;
    background-color: $background;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 10px;
}
*{
  font-size: medium;
}
/* Basic styling for the input box */
input {
    display: flex;
    flex-grow: 1;
    width: 250px; /* Set the width of the input box */
    height: 20px; /* Set the height of the input box */
    padding: 10px; /* Add padding inside the input box */
    border: 1px solid #ccc; /* Add a border with a color */
    border-radius: 15px; /* Round the corners of the input box */
    font-size: 16px; /* Set the font size of the text inside */
    color: $selector-text; /* Set the text color */
    font-size: medium;
    background-color: $input-bg;
       font-weight: 800;
  }
  /* Styling for the placeholder text inside the input box */
  input::placeholder {
    color: #999; /* Set the color of the placeholder text */
  }
  
  /* Hover effect for the input box */
  input:hover {
    border-color: #91a0c0; /* Change border color on hover */
    background-color: $primary-color;
  }
  input:active{
    border: $btn-bg 1px;
  }
  input:focus{
    border: $btn-bg 1px;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .copy-button{
    background-color: $btn-bg;
    color: rgb(253, 255, 255);
    padding: auto;
    height: 40px; 
    border-radius: 10px;
    justify-content: center;
    display: flex;
    margin: auto;
    align-items: center;
    width: 50px;
    font-size: medium;
    font-weight: 700;
    &:focus{
      background-color:$btn-bg;
      animation: blink 3s infinite;
    }

  }
  .copy-button:hover{
    background-color: white;
    color:$btn-bg
  }
  .copy-button:active{
  background-color: aliceblue;
  color: $active-color;
  }

.selector{
  background-color: white;
  text-align: center;  
  margin-top: 30px;
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 1;
  max-width: 100px;
}
.chars{
  min-width: 113px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  align-items: center;
}
.password{
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-content: center;
  margin-top: 55px;
  margin-bottom: 40px;
}
.number{
  width: 25px;
  height: 15px;
  text-align: center;
  margin-left: 30px;
}

.title{
  font-weight: 800;
  font-size: x-large;
  color: $primary-text;
  padding: 20px;
  margin-bottom: 30px;
}

.gen-btn{
  //  position: relative;
  // bottom:100px;
  width:50%;
  margin-top: 40px;
  padding: 7px;
  background-color: $btn-bg;
  border-radius: 10px;
  color:white;
  cursor: pointer;
  &:hover{
    background-color: $hover-color;
    color: $primary-color;
  }
  &:active{
    background-color: $active-color;
    color: $primary-color;
  }
  &:disabled{
    background-color: $disabled-bg;
    color: $disabled-text;
    cursor: not-allowed;
  }
}

.number1{
  width: 10px;
  height: 10px;
}
.link{
  position: absolute;
  bottom:10px;
  left: 70px;
  width: 100%;
  padding: 7px;
  background-color: $btn-bg;
  border-radius: 10px;
  width: 40pc;
  color:white;
  cursor: pointer;
  &:hover{
    background-color: $hover-color;
    color: $primary-color;
  }
  &:active{
    background-color: $active-color;
    color: $primary-color;
  }
}

/* Base styling for the slider */

/* Styling for the slider thumb (handle) */
.letter{
  color: $selector-text;
  font-weight: 500;  
}
