$primary-text: rgb(10, 59, 206);

.uploadBox{
    border: 1px dotted $primary-text;
    padding:5px 50px 20px 50px;
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: start;
    gap: 30px;
    padding-top: 20px;
}

.uploadIcon{
    height: 25px;
    width: 25px;
}
.fileInfo{
    font-size: 15px;
}
.deleteBtn{
    font-size: 24px;
    width: 40px;
    border-radius: 20px;
    transition: font-size 0.5s  ease-in-out;

    :hover{
      font-size: 26px;

    }
}

.errorSection {
    color: red;
    padding: 5px;
    border: 1px solid red;
    background-color: rgb(248, 232, 232);
    margin-bottom: 10px;
    border-radius: 10px;
    max-width: 30pc;
    margin: auto;
    margin-top: 20px;
    font-size: small;
  }
 