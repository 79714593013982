$base-width: 50%; // Initial width
$increase-per-100px: 3%; // Width increase per 100 pixels

.fileInput {
    background-color: white;
    width: 33pc;
    border-style: solid;
    height: 65dvh;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    box-shadow: 8px 20px 10px rgba(57, 131, 242, 0.1);
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    padding: 20px;
    overflow: auto;

  }
    @media screen and (min-width: 1380px) {
      .fileInput {
        width: 50pc;
      }
    }
    @media screen and (max-width: 740px) {
      .fileInput {
        max-width:  20pc;
      }
    }

  .inputContainer{
    display: flex;
    width: 100%;
    gap: 40px;
    justify-content: center;
    align-items: center;
    padding-left: 20px; 
    padding-right: 20px;
  }
  .diffContainer{
    height: 90dvh;
    display: flex;
    overflow: auto;
    margin: 40px;
    border:1px solid rgb(240, 237, 237);
    min-height: 200px;
    max-height: 60dvh;
    padding: 10px 20px 20px 10px;
    border-radius: 20px;
  }

  .title{
    width: 100%;
    display: flex;
    justify-content: start;
    font-size: 20px;
    padding: 0px;
  }

  .pageHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
  }
  .fileSection{
    height: 50px;
    width: 100%;
    position: absolute;
    top:10px
  }